<template>
  <div class="main-page-content">
    <search-condition @handleQuery="handleQuery" :type="'search'"></search-condition>

    <div class="default-table">
      <el-table
          :border="true"
          :data="tableData"
          empty-text="-"
          :header-cell-style="headClass"
          ref="multipleTable"
          row-key="id"
          style="width: 100%"
          tooltip-effect="dark"
      >
        <el-table-column
            align="center"
            label="序号"
            type="index"
            width="80"
        >
        </el-table-column>
        <el-table-column
            align="center"
            show-overflow-tooltip
            label="绩效项目"
            prop="title">
        </el-table-column>
        <el-table-column
            align="center"
            label="状态"
            prop="status"
            width="80">
          <template slot-scope="{row}">
            {{ row.status === 1 ? '未计算' : '已计算' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="绩效录入比例"
            prop="ratio">
          <template slot-scope="{row}">
            {{ row.ratio ? row.ratio + '%' : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="奖金计算"
            prop="bonusState">
          <template slot-scope="{row}">
            {{ row.bonusState === 1 ? '已计算' : '未计算' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作"
            show-overflow-tooltip
        >
          <template slot-scope="{row}">

            <el-button @click="editBtn(row)"
                       size="small" type="text"
            >修改
            </el-button>
            <el-button @click="copyBtn(row)"
                       size="small" type="text"
            >引用复制
            </el-button>
            <el-button @click="delBtn(row)"
                       size="small" type="text"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-row justify="right" type="flex">
        <el-col :span='24' style='text-align: right'>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      @pagination='getList'/>
        </el-col>
      </el-row>
    </div>
    <el-dialog :title="title" :visible.sync="dialogOpen" append-to-body width='550px' center>
      <manage-from :editData="editData" @manageFn="manageFn"></manage-from>
    </el-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import searchCondition from '@/pages/commerce/components/searchCondition.vue'
import manageFrom from '@/pages/commerce/components/manageFrom.vue'

export default {
  name: 'commerceList',
  components: { searchCondition, manageFrom },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      tableData: [],
      searchCondition: {},
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      title: '新增',
      dialogOpen: false,
      editData: {}
    }
  },
  mounted() {
    this.getList()

  },
  methods: {
    //批量样式居中
    headClass() {
      return 'text-align:center'
    },
    handleSearchCondition() {
      let cond = {}
      cond['year'] = this.searchCondition?.year ? this.searchCondition.year : ''
      cond['quarter'] = this.searchCondition?.quarter ? this.searchCondition.quarter : ''
      return cond
    },

    getList() {
      this.tableData = []
      //@todo JJG 对接列表接口 字段格式如下
      for (let i = 0; i < 32; i++) {
        this.tableData.push({
          id: i,
          title: '2023年第1季度',
          status: Math.floor(Math.random() * 2),
          ratio: Math.floor(Math.random() * 100 + 1),
          bonusState: Math.floor(Math.random() * 2),
          year: '2023',
          quarter: 2
        })
      }
      //---
      // let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, {
      //   page_size: this.pagingData.page_size || 10,
      //   current_page: this.pagingData.current_page || 1
      // })
      // const { list, page_info } = await this.$api.XXXX(searchCondition)
      // this.tableData = list
      // this.pageData.current_page = page_info.current_page || 1
      // this.pageData.page_size = page_info.page_size || 1
      // this.total = page_info.total
      // this.loading = false
    },
    editBtn(row) {
      this.dialogOpen = true
      this.editData = row
    },
    handleQuery(data) {
      this.searchCondition = data
      this.pagingData.current_page = 1
      this.getList()
    },
    copyBtn(row) {
      this.editData = row
      delete this.editData.id
      this.dialogOpen = true
    },
    delBtn() {
      //@todo JJG 删除接口 id作为Key
      // let id = row.id

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // const id = await this.$api.XXXX({id:id})
        this.$message({
          type: 'info',
          message: '删除成功'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })

    },
    manageFn() {
      this.editData = {}
      this.dialogOpen = false
    }
  }
}
</script>
