<template>
  <el-form label-position="right" v-model="editData" center label-poionsit='right'
           label-width='120px'>
    <el-form-item label='年度'>
      <el-date-picker
          v-model="editData.year"
          type="year"
          format="yyyy"
          value-format="yyyy"
          placeholder="选择年度" style="width: 140px">
      </el-date-picker>
    </el-form-item>
    <el-form-item label='季度'>
      <el-popover
          placement="right"
          v-model="popShow"
          width="500"
          trigger="click"
      >
        <el-button size="small" type="default" slot="reference">
          选择季度<span v-if="editData.quarter">: 第 {{ editData.quarter }} 季度</span>
        </el-button>
        <el-row>
          <el-col :span="24">
            <el-radio-group v-model="editData.quarter">
              <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                {{ quarter.label }}
              </el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
      </el-popover>
    </el-form-item>
    <el-form-item label='绩效项目' style="width: 300px">
      <el-input v-model='editData.title' placeholder='请输入'/>
    </el-form-item>
    <el-form-item>
      <el-button @click="handleBtn" type="primary">{{ isEdit ? '修改' : '新增' }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  name: 'manageFrom',
  data() {
    return {
      quarters: [
        // { label: '未选季度', value: 0 },
        { label: '第1季度', value: 1 },
        { label: '第2季度', value: 2 },
        { label: '第3季度', value: 3 },
        { label: '第4季度', value: 4 }
      ],
      popShow: false
    }
  },
  props: {
    editData: {
      type: Object
    }
  },
  computed: {
    isEdit() {
      return String(this.editData?.id) != 'undefined' ? true : false
    }
  },
  methods: {
    //@todo JJG 新增修改接口
    handleBtn() {
      if (this.isEdit) {
        //修改接口
        // let id = await this.$api.XXXX(this.editData)
        // if (id) {
        //   this.$emit('manageFn',true)
        // }
      } else {
        //新增接口
        // let id = await this.$api.XXXX(this.editData)
        // if (id) {
        //   this.$emit('manageFn',true)
        // }
      }
    }
  }
}
</script>

<style scoped>

</style>
