<template>

  <el-form :inline="true" label-position="right" v-model="formData" center label-poionsit='right'
           label-width='120px'>
    <el-form-item>
      <el-date-picker
          v-model="formData.year"
          type="year"
          format="yyyy"
          value-format="yyyy"
          placeholder="选择年度" style="width: 140px" @change="handleQuery">
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-popover
          placement="right"
          v-model="popShow"
          width="500"
          trigger="click"
      >
        <el-button size="small" type="default" slot="reference">
          选择季度<span v-if="formData.quarter">: 第 {{ formData.quarter }} 季度</span>
        </el-button>
        <el-row>
          <el-col :span="24">
            <el-radio-group v-model="formData.quarter" @change="handleQuery">
              <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                {{ quarter.label }}
              </el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
      </el-popover>
    </el-form-item>
    <el-form-item>
      <el-button @click="handleQuery" type="primary">查询</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'searchCondition',
  data() {
    return {
      quarters: [
        // { label: '未选季度', value: 0 },
        { label: '第1季度', value: 1 },
        { label: '第2季度', value: 2 },
        { label: '第3季度', value: 3 },
        { label: '第4季度', value: 4 }
      ],
      popShow: false,
      formData: {}
    }
  },

  computed: {},
  methods: {
    handleQuery() {
      this.popShow = false
      this.$emit('handleQuery', this.formData)
    }
  }
}
</script>

<style scoped>

</style>
